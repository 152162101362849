import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
// const size =
//   document.body.clientWidth >= 1024 ||
//   document.documentElement.clientWidth > 1024
//     ? true
//     : false;

const content = [
  "80.png",
  "81.png",
  "82.png",
  "83.png",
  "84.png",
  "85.png",
  "86.png",
  "87.png",
  "88.png",
];

function KeyNotes() {
  return (
    <>
      <div className='section py-5' style={{ background: "#efefef" }}>
        <Container>
          <Row className='justify-content-center'>
            <Col className='ml-auto mr-auto ' md={12}>
              <h1 className='title mb-4 section-title text-center text-dark'>
                <b>THE 2023 EDITION WILL FOCUS ON</b>
              </h1>
              <br />
            </Col>
            {content.map((data, index) => (
              <Col lg={3} xs={6} key={index} className='py-2'>
                <img
                  src={require(`assets/images/icons/${data}`)}
                  alt=''
                  width='100%'
                  className='mx-auto py-2'
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
