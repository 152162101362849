import React from "react";

import { Container, Row, Col, Card, CardImg } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhyAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#192071",
        }}
        className="section section-with-space border-top border-bottom"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-white">
                WHY ATTEND?
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content3.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="py-2">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card className="p-3" style={{ minHeight: "280px" }}>
                    <CardImg
                      src={require(`assets/images/icons/${data.image}`)}
                      style={{ maxWidth: "100px" }}
                      className="mx-auto"
                    />
                    <hr />
                    <h3 className="text-700 pt-3">{data.title}</h3>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  {
    title:
      "One day packed with insights from an astounding lineup of insurance experts",
    image: "3.png",
  },
  {
    title:
      "Panel discussions to deliberate and drill down on the state of GCC’s insurance industry",
    image: "8.png",
  },
  {
    title:
      "Meaningful connections and interactions with peers, speakers and sponsors in a variety of unique ways including virtual chats, 1-1 video conferences, polls and more.",
    image: "5.png",
  },
];

export default WhyAttend;
