import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Slider from "react-slick";

function TextGrid() {
  let pageHeader = React.createRef();
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className='py-2'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={6} className='align-self-center'>
              <h1 className='title mb-4 section-title text-center'>
                <b>The Future Of Insurance</b>
              </h1>
              <br />
              <h3 className='text-dark text-400'>
                The Future of Insurance will focus on an executive view of the
                internal and external trends shaping the insurance industry. It
                will bring together the region's leading insurers, asset
                managers and technology experts from the GCC and overseas to
                discuss the state of GCC’s insurance industry today and in the
                future, explore the best investment strategies in the next phase
                of growth, and examine the dramatic changes due to
                digitalization.
                <br />
                <br />
                Created as a dynamic opportunity for networking, the Future of
                Insurance will be a highly interactive event. With opportunities
                to share insights across sectors, explore and forge new
                partnerships.
                <br />
                <br />
                <Button
                  className='px-3 py-2 rounded-0'
                  color='primary'
                  style={{ minWidth: "200px" }}
                  href='/register'
                >
                  <p className='m-0 text-700' style={{ color: "#fff" }}>
                    REGISTER INTEREST TO ATTEND
                  </p>
                </Button>
              </h3>
            </Col>
            <Col lg={6}>
              <img
                src={require("assets/images/homepage.png")}
                width='100%'
                alt='textgrid'
              />

              {/* <img
                src={require(`assets/images/homepage.jpg`)}
                width="100%"
                alt="Finnovex"
              /> */}
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col xs={11} className='mt-5'>
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width='100%'
                      // style={{ maxWidth: "728px" }}
                      alt=''
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [];

export default TextGrid;
