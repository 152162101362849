import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

// import Slider from "react-slick";

function Sponsor({ sponsors }) {
  const rows = [["NETWORKING SPONSOR", "AI & ANALYTICS PARTNER", "ASSOCIATE SPONSOR"]];
  const newrows = [
    ["GOLD SPONSOR", "STRATEGIC SPONSOR", "KNOWLEDGE PARTNER"],
    ["NETWORKING SPONSOR", "SILVER SPONSOR"],
    ["SUPPORTING ASSOCIATION"],
  ];
  // const settings = {
  //   dots: true,
  //   autoplay: true,
  //   arrows: true,
  //   autoplaySpeed: 4000,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   className: "p-10",
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <div
        style={{
          backgroundColor: "e0e4e5",
          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center text-main section-title">
                <b>2023 SPONSORS & PARTNERS</b>
              </h1>
            </Col>
          </Row>
          {/* {newrows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                newSponsors[sponsor].map((s) => (
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <h3
                      className='text-dark text-400 text-uppercase text-center pb-3'
                      style={{ margin: 0, padding: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: s.type,
                      }}
                    ></h3>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <Card
                        className='mx-auto'
                        style={{ borderRadius: 0, maxWidth: s.size }}
                      >
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          alt={s.name}
                          width='100%'
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))} */}

          {newrows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                newSponsors[sponsor].map((s) => (
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <h3
                      className="text-dark text-400 text-uppercase text-center pb-3"
                      style={{ margin: 0, padding: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: s.type,
                      }}
                    ></h3>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <Card className="mx-auto" style={{ borderRadius: 0, maxWidth: s.size }}>
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          alt={s.name}
                          width="100%"
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");

export const sponsors = [
  // {
  //   name: "Magnati",
  //   pageLink: "Magnati",
  //   type: "DIGITAL PAYMENTS PARTNER",
  //   image: "magnati.jpg",
  //   text: [``],
  //   link: "https://www.magnati.com/",
  //   size: "80%",
  //   grid: 6,
  // },
  {
    name: "Policy Bazaar",
    pageLink: "Policy Bazaar",
    type: "GOLD SPONSOR",
    image: "pb.jpg",
    text: [``],
    link: "https://www.policybazaar.ae",
    size: "80%",
    grid: 4,
  },
  {
    name: "Uniqus",
    pageLink: "Uniqus",
    type: "STRATEGIC SPONSOR",
    image: "Uniqus-Logo-JPEG.jpg",
    text: [``],
    link: "https://uniqus.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Taxation Society",
    pageLink: "Taxation-Society",
    type: "KNOWLEDGE PARTNER",
    image: "taxation.png",
    text: [``],
    link: "https://taxationsociety.org/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Millionaire Aisle",
    pageLink: "Millionaire Aisle",
    type: "NETWORKING SPONSOR",
    image: "million.png",
    text: [``],
    link: "https://www.millionaireaisle.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "BSA",
    pageLink: "BSA",
    type: "SILVER SPONSOR",
    image: "BSA-Logos-Print-CMYK-08-1.png",
    text: [``],
    link: "https://bsabh.com/",
    size: "80%",
    grid: 4,
  },
  
  {
    name: "DIFC Insurance Association",
    pageLink: "DIFC",
    type: "SUPPORTING ASSOCIATION",
    image: "difc.png",
    text: [
      `Mozn is the leading advanced analytics solutions and product firm in the Middle East, unlocking the true
      potential of its analytics talent to deliver value globally.
      Mozn aims to achieve market leadership in building and deploying bespoke advanced analytics solutions
      in the Middle East, and develop data and analytics driven products in niche areas globally.`,
    ],
    link: "",
    size: "80%",
    grid: 3,
  },
  {
    name: "Emirates Insurance Association",
    pageLink: "Emirates Insurance Association",
    type: "SUPPORTING ASSOCIATION",
    image: "eia.png",
    text: [``],
    link: "http://eiauae.net/En",
    size: "80%",
    grid: 3,
  },
  {
    name: "Insurance Business Group",
    pageLink: "Insurance Business Group",
    type: "SUPPORTING ASSOCIATION",
    image: "IBG New Logo (1).png",
    text: [``],
    link: "https://www.ibguae.com/",
    size: "80%",
    grid: 3,
  },
];
const newSponsors = groupByType(sponsors);

export default Sponsor;
