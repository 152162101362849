import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import { Redirect } from "react-router-dom";

function Register({ type, successMsg }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");

  const [details, setDetails] = React.useState({
    type,
    utm_source,
    utm_medium,
    utm_campaign,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    if (reg.test(details.email)) {
      setLoading(false);
      setError("Please enter your business email only");
      return null;
    }

    fetch(
      "https://us-central1-futureofinsurance.cloudfunctions.net/api/email",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    )
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        // setSuccess(
        //   successMsg
        //     ? successMsg
        //     : "Thank you for your request. We'll get back to you shortly."
        // );
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  let inputs = content;
  if (type === "BROCHURE") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "REPORT") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "SPONSOR") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6, 7].includes(c.id));
  }
  if (type === "MEDIA") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5].includes(c.id));
  }
  if (redirect) {
    return <Redirect to={`/thank-you/${type}`} />;
  }
  return (
    <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#192071",
        overflow: "hidden",
      }}
    >
      <Container>
        <Row>
          <Col className='mx-auto mb-5 py-5' lg={8}>
            <Container className='my-auto text-white text-uppercase text-400'>
              {type === "REGISTER" && <p></p>}
              <Form onSubmit={onSubmitForm} className='register-form'>
                <Row className='justify-content-center text-white'>
                  {inputs.map((i) => (
                    <Col lg={6} className='my-2' key={i.name}>
                      <label>{i.placeholder}*</label>
                      <Input
                        placeholder={i.placeholder}
                        type={i.type}
                        name={i.name}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  ))}
                  {type === "REGISTER" && (
                    <Col lg={6} className='my-2'>
                      {/* <label>PricePlan*</label>
                      <Input
                        type="select"
                        name="priceplan"
                        id="priceplan"
                        onChange={onChangeDetails}
                        required
                      >
                        <option value="">SELECT</option>
                        <option>FREE</option>
                        <option>AED 350</option>
                      </Input> */}
                      <label>Industry *</label>
                      <Input
                        type='select'
                        name='industry'
                        id='industry'
                        onChange={onChangeDetails}
                        required
                      >
                        <option value=''>SELECT</option>
                        {industries.map((industry) => (
                          <option key={industry}>{industry}</option>
                        ))}
                      </Input>
                    </Col>
                  )}
                  <Col lg={12} className='text-center pt-2'>
                    <p style={{ textTransform: "none" }}>
                      By clicking download/submit you agree to receive further
                      marketing information from Khaleej Times Events on our
                      series of live and digital events. Please{" "}
                      <a href='/privacy-policy' className='text-primary'>
                        read our privacy policy here.
                      </a>
                    </p>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className='mt-3' color='warning' size='sm' />
                  </span>
                ) : (
                  <Button
                    block
                    className='btn text-white text-700 mt-3'
                    color='primary'
                    size='lg'
                    type='submit'
                    disabled={loading}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className='text-center'
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "first_name", type: "text", placeholder: "First Name" },
  { id: 2, name: "last_name", type: "text", placeholder: "Last Name" },
  { id: 3, name: "email", type: "text", placeholder: "Business Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 5, name: "company", type: "text", placeholder: "Company" },
  { id: 6, name: "job", type: "text", placeholder: "Job Title" },
  { id: 7, name: "country", type: "text", placeholder: "Country" },
  // { id: 8, name: "nationality", type: "text", placeholder: "Nationality" },
];

const industries = [
  "Retail",
  "BFSI",
  "E-Commerce",
  "Conglomerates",
  "Manufacturing & Industrials",
  "Healthcare",
  "Education",
  "Government",
  "Other",
];
