export const speakers = [
  {
    name: "Hamad Abdullah Al Mehyas",
    title: "Chief Executive Officer",
    company: "National Health Insurance Company – Daman, UAE",
    image: "hamad.jpg",
    companyImage: undefined,
    text: [
      `Hamad Abdullah Al Mehyas serves as Daman’s Chief Executive Officer. Under Mr Al Mehyas’
      leadership Daman continues to be the leading health insurer and value-sharing health
      partner to the Government.`,
      `Mr Al Mehyas has overseen the development and implementation of key strategic initiatives
      to provide unparalleled smart solutions and services to help shape and support a healthier
      community in the UAE. His extensive knowledge within the healthcare system has helped
      enhance Daman’s industry leading expertise and ensured the delivery of excellence across
      all its products and services. Prior to his current role, Mr Al Mehyas has served in operational
      and commercial executive roles during his six years at Daman.`,
      `He is one of the first UAE Nationals specialising in Forensic Medicine where he received the
      Government Excellence Award for Innovative Initiative. He has held several senior roles in
      Federal and Local Government entities and led the Office of UAE National Supreme
      Biosecurity Committee. He is a licensed Forensic Medicine Specialist, a member of the
      International Society for Forensic Genetics, Germany. Additionally, and was a member of the
      UK Forensic Science Services.`,
      `Mr Al Mehyas serves as General Manager of Daman Healthcare Solutions, as Board Member
      at inHealth - Independent Health Information Technology Services and as Board member at
      Saudi Enaya Cooperative Insurance Company. He also sits at Emirates Insurance
      Association’s Board as Vice Chairman, Chairman of Higher Technical Committee as well as
      Chairman of the Life &amp; Medical Committee and Chairman of the Emiratisation Committee.
      Mr Al Mehyas holds a master’s degree in DNA Profiling from the University of Central
      Lancashire and a Bachelor of Science in Forensic Medicine from Glamorgan University.
      During his career he has been awarded several honorary medals and certificates of
      appreciation from Government and International Organisations.`,
    ],
    uae: true,
  },

  {
    name: "Jihad Faitrouni",
    title: "Chief Executive Officer",
    company: "Dubai Islamic Insurance & Reinsurance Company",
    image: "jihad.jpg",
    companyImage: undefined,
    text: [
      `Mr. Jihad Faitrouni's life career started in 1990 in ARUNCO till 1999 and became an advisor for MOE-
    Lebanon from 1995 till 1997, then as the GM for UIS from 1999 - 2001. From 2001 - 2004
    he was the CEO of Aman Takaful Insurance - Lebanon and then he moved to UAE as a GM
    for Dubai Islamic Insurance &amp; Reinsurance (AMAN) where he worked on a special
    assignment along his duties as a GM, in establishing Kuwait Boubyan Insurance until 2008.
    From 2010 to 2013, he was assigned as an advisor in the MOF- UAE and in 2013 he was
    back to AMAN-Dubai as the CEO.
    He was approached by Sorbonne University Abu Dhabi as a lecturer.
    Professional Fellowship :
    <br/>
    1. Chairman of  the Takaful Technical Committee (EIA)-UAE<br/>
    2. Governing Council Member of IBG<br/>
    3. Member of the Emirate Insurance Association (EIA)- UAE<br/>
    4. Member of Hawkama Institute for Corporate Governance (DIFC)- UAE<br/>
    5. Member of the IRM- UK`,
    ],
    uae: true,
  },
  {
    name: "Walter Jopp",
    title: "Chief Executive Officer",
    company: "Zurich Insurance Company",
    image: "walter.png",
    companyImage: undefined,
    text: [
      `Walter Jopp is Chief Executive Officer for Zurich in the Middle East. His role is
    responsible for developing and executing Zurich’s strategy in the region, enhancing the
    value of Zurich’s strategic partnerships and identifying opportunities for further
    profitable growth.<br/>
    In his career at Zurich, spanning over 26 years, Walter has held several senior roles
    within Asia Pacific, Europe, Latin America and the Middle East. These have included
    developing key bank relationships at a global level, driving Zurich’s international
    business in Latin America and Scandinavia as well as acting as Global Head of Sales,
    building and leading sales operations for individual and corporate businesses with
    teams in the Americas, Europe, Middle East and Asia.<br/>
    Until October 2015, he led the Market Management team for Zurich in Asia Pacific and
    Middle East, overseeing sales, marketing and propositions in the region.`,
    ],
    uae: true,
  },
  {
    name: "Mohamed Seghir",
    title: "Chief Executive Officer",
    company: "AXA Green Crescent Insurance Company",
    image: "Mohamed.jpg",
    companyImage: undefined,
    text: [
      `Mohamed started his career in 2007 as an Actuary at FWU AG in Munich, where he was
    responsible for Western Europe and the Middle East. He then took up the role of Manager
    Actuarial Services for Munich Re before joining Swiss Re in Zurich in 2013, initially as a
    Senior Marketing Actuary in charge of pricing of the life & health business in MENA, Turkey
    and Pakistan before becoming the Head of North Africa (P&C and L&H) and Middle East
    (L&H). Mohamed has been appointed CEO of AXA Green Crescent in April 2018.<br/>
    Mohamed is a regular contributor to seminars, conferences and publications for the
    insurance industry. Passionate about insurance, Mohamed has developed a deep
    expertise on various topics such as bancassurance, life insurance, takaful and pension,
    only to name a few.<br/>
    Mohamed studied in the prestigious ISFA (Institut des Sciences Financières et des
    Assurances) and holds an MSc in Finance and Actuarial Sciences and a BSc in Finance
    from Université Claude Bernard (Lyon, France), and is a fully qualified actuary from the
    French (IA) and Swiss (SAV) Institutes of Actuaries.`,
    ],
    uae: true,
  },
  // {
  //   name: "Jugal Madaan",
  //   title: "Executive Vice President",
  //   company:
  //     "Underwriting (Commercial Lines), Abu Dhabi National Insurance Company, UAE",
  //   image: "jugal.png",
  //   companyImage: undefined,
  //   text: [
  //     `Jugal Madaan currently holds the position of Executive Vice President at Abu Dhabi National Insurance Company (ADNIC), responsible for Commercial Lines Underwriting of the company.`,
  //     `Mr. Madaan joined ADNIC in 2007 as Senior Manager/Head of Fire & General Accident Department. He was promoted as Deputy Chief Underwriting Officer in 2009 and as Chief Underwriting Officer in 2013. `,
  //     `Up to 2007, Mr. Madaan was with the Indian government company: Oriental Insurance, where he served as Senior Divisional Manager & Head of their largest Division in Mumbai.  During 2001 to 2006, he was the Country Head of Oriental Insurance – Kuwait, where he was responsible for the overall management of the Kuwait business. `,
  //     `During his career, Mr. Madaan has been part of the key achievements. These include effectively managing the large portfolio comprising of the key clients across the spectrum in ADNIC. Wining the “Best Divisional Office Award” in Oriental Insurance, India; and achieving the best ever results for Oriental Insurance in Kuwait, where he turned around the performance of the company achieving growth with high profitability. `,
  //     `Jugal Madaan holds MBA and LLB degrees from India. He is a Fellow of the Chartered Insurance Institute, London (UK); Fellow/CIP of ANZIIF, Australia; Fellow of Risk Management Society, USA; Life Fellow of Insurance Institute of India, Mumbai; and Life Fellow of Institute of Directors, India. He is a Chartered Property Casualty Underwriter qualified from the AICPCU, USA. He holds Associate Diplomas from The Institutes, USA in various streams namely: Risk Management; Commercial Underwriting; Insurance Service; Risk Management for Public Entities; Claims; Marine Insurance Management; Reinsurance and also in Insurance Regulations. He achieved CRIS and MLIS designations from IRMI, USA in 2015. `,
  //     `Mr. Madaan was a member of the International Council of  the Risk Management Society, New York from 2017 to 2019. He has presented several papers at international insurance forums as well as contributed articles that were published in several industry related publications. `,
  //   ],
  //   uae: true,
  // },
  {
    name: "Sivakumar Seshadri",
    title: "Chief Operating Officer",
    company: "Al Rajhi Takaful",
    image: "Sivakumar.jpg",
    companyImage: undefined,
    text: [
      `Sivakumar Seshadri is the Chief Operating Officer for AL Rajhi Takaful Market leading insurance for
    Motor Insurance and Third largest insurer in KSA, Siva has 28+ years of experience in the insurance
    industry with, experience spread across Insurance Operations, Digitization and Technology
    transformation for leading insurers across various continents. He has been instrumental in driving a
    number of digital initiatives across global insurers which helped in multi year accelerated revenue
    growth and market leading combined ratios. In his past experience Siva worked in leading ship roles
    with Infosys , Cap Gemini ( Igate), Tata consultancy Services and LIC of India  across Insurance
    Operations, Technology Management, Strategic Consulting and Business Transformation, He worked
    across various continents supporting leading Insurers in their transformative initiatives.<br/>
    Siva is a ACII and Fellow of Insurance Institute of India and holds a Masters in Commerce with
    specialization in Insurance.`,
    ],
    uae: true,
  },

  {
    name: "Chalapathi Srinivasan",
    title: "Executive Director",
    company: "Joie De Vivre International Insurance Brokerage LLC, UAE",
    image: "srini.jpg",
    companyImage: undefined,
    text: [
      `Chalapathi Srinivasan is a qualified insurance professional with extensive General
    Insurance and Reinsurance experience of over 32 years. He holds a Fellowship in
    General Insurance. He has led the management and operations in Insurance
    Companies as well as Broking firms during his tenure both in India and GCC in various
    senior management capacities.`,
      `He is currently the Executive Director of JdV International Insurance Brokerage LLC in
    Dubai. He handles Reinsurance Treaties, product conceptualisation and General
    Insurance business of ‘JdV International’ besides designing aggregation models for
    affinity groups and community schemes. He has published Insurance related articles
    and is a regular speaker at Insurance summits and conferences.`,
      `He was the Director of Avon Insurance and Reinsurance Brokers, CEO of Driesassur
    Middle East in UAE and Senior Executive Officer of Bahrain National Insurance in Qatar
    / Bahrain prior to his joining ‘JdV International’. A start-up and business revival expert,
    he has successful track record in operations, new product development and
    underwriting. He is an expert in Financial lines, Reinsurance, Property and casualty
    Insurances.`,
      `He is an expert in Insurance solutions and Claims Consulting. He has handled mega
    and major projects in different type of industries within GCC and India.`,
    ],
    uae: true,
  },
  {
    name: "Hasheer Kottal",
    title: "Senior Vice President",
    company: "Bancassurance, Mashreq, UAE",
    image: "hasheer.jpg",
    companyImage: undefined,
    text: [
      `Hasheer Kottal is the Senior Vice President &amp; Business Manager, Bancassurance at Mashreq for its MEA
      businesses.
      In his current role, Hasheer is responsible for the management of overall Bancassurance business across
      the Retail, Private banking and SME segments for Mashreq.
      He is instrumental in the set up and execution of successful Bancassurance businesses in various
      organization across the region in both Life and non-life business.`,
    ],
    uae: true,
  },
  {
    name: "Rakesh Sudhakaran",
    title: "Senior Vice President",
    company: "Bancassurance, Oman Insurance Company",
    image: "rakesh.jpg",
    companyImage: undefined,
    text: [
      `Rakesh Sudhakaran joined Oman Insurance Company in 2017 as Head of Bancassurance. He is
    currently responsible to manage the Life, General and Commercial insurance business through
    Bancassurance and Affinity distribution.
    He is a seasoned financial services professional with 20+ years of experience, including 16 years
    in the Middle East market. Before joining Oman Insurance, he held various senior positions with
    Zurich, Mashreq and ALICO in the Middle East. Rakesh has a passion for digital distribution
    and he was instrumental in pioneering a few of the successful digital Bancassurance &amp; Affinity
    distribution models in the region through strategic partnerships.
    Rakesh holds a Bachelor’s degree in Commerce and holds an MBA in Marketing and Finance.`,
    ],
    uae: true,
  },
  {
    name: "Vasilis Katsipis",
    title: "General Manager",
    company: "Market Development, AM Best",
    image: "vasilis.jpg",
    companyImage: undefined,
    text: [
      `Vasilis Katsipis is based in Dubai and is General Manager, Market Development of AM
    Best for MENA, South &amp; Central Asia.<br/>
    He has been with AM Best for the last twelve years, having joined the company in
    London as a managing senior financial analyst and growing to the position of general
    manager, analytics for EMEA. He has written several pieces focusing on insurance and
    regulation in Europe and the MENA region and has participated in international bodies
    focused on establishing global standards for insurance.<br/>
    Prior to joining AM Best Vasilis was responsible for alternative distribution channels for
    GE Insurance Solutions. He has over 20 years of insurance and reinsurance experience,
    having focused on both the life and non-life sectors. He has held roles in high level
    operational and strategic positions. For most of his career, he has held international
    positions with either regional or global responsibilities.<br/>
    Vasilis holds an MBA from University of Wales and a BSc in economics from Piraeus
    University.`,
    ],
    uae: true,
  },
  {
    name: "Mahmoud Ghulman",
    title: "Director of Business Development",
    company: "Mozn",
    image: "mahmoud.jpg",
    companyImage: undefined,
    text: [
      `Mahmoud Ghulman is the Director of Business Development at Mozn, the leading data science and data
    products company in the Middle East. Prior to Mozn, Mahmoud founded several tech startups in the IoT
    and insurance spaces. He graduated with honors from MIT with a degree in Physics.`,
    ],
    uae: true,
  },
  {
    name: "David T. Youssef",
    title: "Managing Director, Middle East, Africa and Asia",
    company: "Vumi Global Services FZ-LLC",
    image: "david.jpg",
    companyImage: undefined,
    text: [``],
    uae: true,
  },
  {
    name: "Yousuf Amin",
    title: "Executive Vice President, Consumer Lines Claims",
    company: "Executive Vice President, Consumer Lines Claims",
    image: "yousuf.jpg",
    companyImage: undefined,
    text: [``],
    uae: true,
  },
  {
    name: "Fareed Lutfi",
    title: "Secretary General",
    company: "Emirates Insurance Association, and Gulf Insurance Federation",
    image: "fareed.png",
    companyImage: undefined,
    text: [
      `With nearly 40 years of experience, Fareed Lutfi continues to
    work patiently as a powerful lobbyist for the cause of
    insurance and correct insurance practice across the UAE
    and the GCC.<br/>
    Fareed Lutfi is currently the Secretary General, Emirates
    Insurance Association, and Gulf Insurance Federation.
    He is a Board member of Old Mutual M.E. at DIFC,
    Executive Advisor at Marsh Emirates in the UAE.
    He has served as an executive, board member and on
    advisory committees of insurance and reinsurance entities in
    Bahrain, Kuwait, Oman, UAE, Turkey and DIFC; He also
    served as Group Director, Insurance Services for Dubai
    Holding, as well as president of Dubai Insurance Group at
    Dubai Holding.<br/>
    He was the Director &amp; Chief Executive Officer of Alliance
    Insurance, General Manager of Dubai Islamic Insurance and
    Reinsurance Company (AMAN) Dubai.
    Fareed was also a Board Member and advisor of Dubai
    International Financial Centre across the spectrum of
    insurance and reinsurance issues.<br/>
    His career began with ARIG – Arab Insurance Group in 1981
    through 1996, setting up and managing the London Contact
    office from 1982 to 1986.`,
    ],
    uae: true,
  },
  {
    name: "Franck Heimburger",
    title: "Chief Personal Lines Officer",
    company: "AXA Gulf",
    image: "frank.jpg",
    companyImage: undefined,
    text: [
      `Franck Heimburger is the Chief Personal Lines Officer for AXA in the Gulf
    region since January 2018. He is in charge of the full value chain for all
    lines of business for individual customers. This role embraces Retail
    Product Development and Underwriting, Retail Distribution (direct
    brokers and bancassurance), P&C Claims as well as the Data Analytics
    function.<br/>
    Prior to his appointment in 2018, Franck was the Chief Sales & Distribution
    Officer, responsible for supervising the broker business, determining the
    appropriate Sales & Distribution strategy in line with the required
    financial and operational targets. Prior to that role, he was the Country
    Manager of AXA Gulf’s operations in the UAE.<br/>
    Franck joined the AXA Group in 1995 and has over 21 years of experience
    in the insurance industry. He held various leadership roles within AXA
    France before moving to AXA Gulf in 2013. He has strong expertise in the
    Sales & Distribution Management of Life, Health and General Insurance.
    He holds a post-graduate degree from the Aix-Marseille Université,
    France, with specialisation in insurance law and has also completed
    Telemaque Management Program from Insead Fontainebleau.`,
    ],
    uae: true,
  },
  {
    name: "Aarti Khurana",
    title: "Vice President - Strategic Initiatives & Bancassurance",
    company: "Union Insurance",
    image: "aarti.jpg",
    companyImage: undefined,
    text: [
      `Aarti is a Business Strategy Leader, Change Driver, with a solid
    experience in Financial &amp; Affinity Sector for over 14 years..
    She has worked with many prestigious organizations – ABN Amro,
    Mashreq Bank. Aarti holds a Master’s degree with Specialization in
    Sales &amp; Marketing.<br/>
    At Union Insurance, leading the Strategic Initiatives &amp;
    Bancassurance, Aarti has been leveraging/ disrupting insurance
    initiatives with several industries - Banking, Telecom, Airlines &amp;
    many Affinity Partners which has created many
    Distruptions/Transformations &amp; lead to developing success stories in
    Many Markets and initiate many JV’s. Disruption, Collaboration &amp;
    Complimenting through Customer Engagement are a few key
    strengths that have been brought to the insurance industry.`,
    ],
    uae: true,
  },
  {
    name: "Xavier Arputharaj",
    title: "Deputy Chief Executive Officer",
    company: "Orient Insurance PJSC",
    image: "Xavier.png",
    companyImage: undefined,
    text: [``],
    uae: true,
  },
  {
    name: "Michael Kortbawi",
    title: "Partner",
    company: "BSA Ahmad Bin Hezeem & Associates",
    image: "michael.jpg",
    companyImage: undefined,
    text: [
      `Michael has gained hands on experience in the Middle East having practiced in the United Arab
    Emirates for over 16 years and supervised the organic growth of BSA Ahmad Bin Hezeem &amp;
    Associates LLP into one of the largest law firms in the region.<br/>
    Based in the DIFC and heading a team of eight corporate lawyers, Michael works across
    multiple jurisdictions and has spearheaded some of the landmark insurance deals in the region
    for his local and multinational clients.`,
    ],
    uae: true,
  },
];

export const sponsors = [
  {
    name: "CME",
    pageLink: "cme",
    type: "NETWORKING SPONSOR",
    image: "cme.png",
    text: [
      `We are a multinational technology consulting firm. We have helped over 100 clients worldwide,
    including top US Fortune 500 companies, to become and remain leaders in their fields.
    We provide premium end-to-end tailor-made solutions and technology services across different
    disciplines, including Staff Augmentation, Custom Software Development, Smart Devices
    Engineering, Customer Experience, Internet of Things, Artificial Intelligence, Data Management, and
    Process Automation.
    We accelerate the digital transformation for businesses and corporations by developing scalable and
    forward-thinking projects to achieve operational excellence, improve customer engagement, and
    unlock new growth opportunities. Our proven track record of success spans across several industries,
    including Consumer Goods, Food, Healthcare, Hospitality, Insurance, Market Research, Retail,
    Telecom, and Utilities.
    Our teams of highly skilled engineers, creative thinkers, and industry-specific experts from 5
    locations across the globe delivered more than 250 innovative projects. To date, we served 50M+
    users and contributed to 7 US Patents.
    If you believe in the transformative power of technology: join us in Reimagining Everything!`,
    ],
    link: "https://www.gotocme.com",
    size: "80%",
    grid: 4,
  },
  {
    name: "SAS",
    pageLink: "sas",
    type: "AI & ANALYTICS PARTNER",
    image: "sas.jpg",
    text: [
      `SAS is the leader in analytics. Through innovative software and services, SAS empowers and inspires customers around the world to transform data into intelligence. SAS gives you THE POWER TO KNOW®.

      `,
    ],
    link: "https://www.sas.com/me",
    size: "80%",
    grid: 4,
  },

  {
    name: "MOZN",
    pageLink: "Mozn",
    type: "ASSOCIATE SPONSOR",
    image: "mozn.png",
    text: [
      `Mozn is the leading advanced analytics solutions and product firm in the Middle East, unlocking the true
      potential of its analytics talent to deliver value globally.
      Mozn aims to achieve market leadership in building and deploying bespoke advanced analytics solutions
      in the Middle East, and develop data and analytics driven products in niche areas globally.`,
    ],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "AMBEST",
    pageLink: "AMBEST",
    type: "ASSOCIATE SPONSOR",
    image: "ambest.png",
    text: [
      `Founded in 1899, AM Best is the world’s largest credit rating agency specializing in
      the insurance industry. Headquartered in the United States, the company does
      business in over 100 countries with regional offices in New York, London,
      Amsterdam, Dubai, Hong Kong, Singapore and Mexico City.
      AM Best Rating Services assesses the creditworthiness of and/or reports on over
      16,000 insurance companies worldwide. Our commentary, research and analysis
      provide additional insight.
      AM Best Information Services integrates credit ratings, commentary, research and
      analysis with insurance news, financial data and thought leadership to help
      consumers and professionals make informed personal and business decisions.`,
    ],
    link: "https://www.ambest.com",
    size: "80%",
    grid: 4,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    id: "Hr",
    title: "Feeling unwell and want the day off in Abu Dhabi? Go to HR robot",
    image: "1.png",
    link: "Feeling-unwell-and-want-the-day-off-in-Abu-Dhabi--Go-to-HR-robot",
  },
  {
    id: "Hyperloop",
    title:
      "Hyperloop systems in UAE, other countries now closer to reality: DP World",
    image: "2.png",
    link: "Hyperloop-systems-in-UAE",
  },
  {
    id: "Advancements",
    title: "The advancements in real world Artificial Intelligence",
    image: "3.png",
    link: "The-advancements-in-real-world-Artificial-Intelligence",
  },
  {
    id: "Bigdata",
    title: "AI and Big Tech should not have control over health",
    image: "4.PNG",
    link: "AI-and-Big-Tech-should-not-have-control-over-health",
  },
  {
    id: "Coop",
    title: "The GCC: A bright future",
    image: "5.PNG",
    link: "The-GCC-A-bright-future",
  },
  {
    id: "AIbots",
    title: "AI bots to boost care at Saudi German Hospital",
    image: "6.PNG",
    link: "AI-bots-to-boost-care-at-Saudi-German-Hospital",
  },
  {
    id: "Transform",
    title:
      "Digital transformation to drive recovery of GCC retail sector post-Covid-19",
    image: "7.PNG",
    link: "Digital-transformation",
  },
  {
    id: "Cyber",
    title: "Cyber security vital to Dubai's sustained progress: Sheikh Hamdan",
    image: "8.PNG",
    link: "Cyber-security-Sheikh-Hamdan",
  },
];
