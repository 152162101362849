import React from "react";
import {
  Container,
  // Button,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d) => (
      <Col lg={12}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card style={{ backgroundColor: "#192071" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col lg={2} className="align-self-center">
                    <h2 className="text-500 text-white"> {d.time}</h2>
                  </Col>
                  <Col lg={10} className="align-self-center text-white">
                    <h2 className="text-400">{d.title}</h2>
                    {d.description && (
                      <h3
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md={12}>
                <h1 className="title mb-1 section-title text-center">
                  <b>Agenda At a Glance</b>
                </h1>
                <br />
              </Col>
              <Col md="12">
                <TabContent activeTab={"1"}>
                  <TabPane tabId="1">
                    <Container>
                      <Row>
                        <AgendaCard data={content1} />
                        {/* <Col lg={12} className={"text-center"}>
                          <Button
                            href='/sponsor/download-brochure'
                            className='btn'
                            color='info'
                            size='md'
                          >
                            Request Full Agenda
                          </Button>
                        </Col> */}
                      </Row>
                    </Container>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "08.30",
    title: "REGISTRATIONS AND NETWORKING",
  },
  {
    time: "09.30",
    title: "WELCOME BY KHALEEJ TIMES",
    description: `
    `,
  },
  {
    time: "09.40",
    title: "Industry Welcome",
    description: `Roadmap To Develop An Integrated Insurance System For The U.A.E.<br/><br/>
    Speaker<br/>
Fareed Lutfi, Secretary General, Emirates Insurance Association
    `,
  },
  {
    time: "10:00",
    title: "Keynote Address",
    description: `UAE Insurance Ecosystem – Ready for the big leap<br/><br/>
    Speaker<br/>
Simon Price, Chief Executive Officer, DIFC Insurance Association
    `,
  },
  {
    time: "10.20",
    title: "Fireside Chat - Takaful Industry: Growth, Opportunities, and Challenges",
    description: `Takaful Insurance shows immense potential for the future growth and development of the insurance sector in the UAE. This chat between two takaful thought leaders will emphasize the need for proactive measures such as technological adoption, product innovation, awareness campaigns, and regulatory enhancements to unlock this potential.
    <br/><br/>
    Speaker<br/>
Walter Jopp, Chief Executive Officer, SALAMA – Islamic Arab Insurance Co.<br/><br/>
Moderator<br/>
Anurag Chaturvedi, Chief Executive Officer, Andersen UAE
    
    `,
  },
  {
    time: "10.40",
    title:
      "Opening Stakeholder Panel Discussion - Navigating the Complexities of Merger & Acquisitions - Are We Ready?",
    description: `
    As the insurance industry continues to undergo significant consolidation through mergers and acquisitions, the challenges and opportunities for companies involved in these transactions are becoming increasingly complex.
    The opening panel discussion will gather thought leaders with real-world case studies to explore the critical issues surrounding mergers and acquisitions in the insurance industry.
    <br/>
    <br/>
    <ul>
    <li>Evaluating whether merger or acquisition is the right strategic move for your organization</li>
    <li>Analyzing recent trends in insurance M&amp;A activity</li>
    <li>Understanding the potential risks and benefits of different M&amp;A structures</li>
    </ul><br/>

    Speakers<br/>
Aftab Hassan, Chairman, Risk Exchange DIFC Limited<br/>
David Youssef, Managing Director, VUMI Global <br/>
Serena Mujtaba, Group Business Planning, National Life & General Insurance Company<br/>
Simon Isgar, Partner and Head of Insurance/Reinsurance, BSABH<br/><br/>
Moderator<br/>
Venkateswaran Narayanan, Partner, Uniqus Consultech
    `,
  },
  {
    time: "11.25",
    title: "Networking Activity",
    description: ``,
  },
  {
    time: "11.40",
    title: "Networking and Pre-Lunch",
    description: ``,
  },
  {
    time: "12.15",
    title: "Panel discussion -  Insuretech 3.0?",
    description: `Insurtech companies are emerging as key players in the insurance market, challenging traditional insurance companies with their innovative products and services.
    <br/>Insurtech is rapidly transforming the insurance industry, with new technologies and innovations emerging on a regular basis. To stay competitive, insurance companies must stay up-to-date with the latest insurtech trends and adopt new strategies to leverage these technologies. This panel discussion will explore the insurtech trends for 2023 and beyond, and how they will shape the future of the insurance industry.<br/><br/>
    <ul>
<li>Improving customer experience and engagement with personalized policies, usage-based insurance, and automated claims processing</li>
<li>Impact on traditional insurance such as auto, health, life, and property insurance.</li>
<li>Potential of collaboration between insurers and insurtech companies to stay ahead of the curve</li>
</ul>
<br/>
Speakers	<br/>
Carole Khalife, General Manager – Strategy & Innovation, Al Futtaim Willis<br/>
Neeraj Gupta, Chief Executive Officer, Policybazaar.ae<br/>
Stefano Nalin, EVP - Chief Business Development Officer, Abu Dhabi National Insurance Company	<br/><br/>
Moderator<br/>
Rakesh Sudhakaran, Senior Vice President, Bancassurance, Sukoon Insurance

    `,
  },
  {
    time: "13.00",
    title: "Conversation - Engaging With the Next Generation",
    description: `Millennials and Gen Z have become the dominant consumers of insurance products - These
    generations have different attitudes and expectations. The panel will provide a blueprint to remain
    competitive, by adapting to changing dynamics and finding new ways to engage with and serve
    the new generation of customers.
    <br/>
    <br/>
    <ul>
<li>Providing more personalized and transparent products with a focus on sustainability and social responsibility</li>
<li>Innovations resonating with younger customers, such as usage-based insurance, on-demand coverage, and peer-to-peer insurance.</li>
<li>Leveraging digital channels and platforms to create more engaging and interactive experiences</li>
</ul><br/>
Speakers<br/>
Komal Shivdasani, Vice President, Head of International, Logistics & Insurance Management, First Abu Dhabi Bank<br/>
Varghese Skariah, Group Head of Data, National Life and General Insurance Company<br/><br/>
Moderator<br/>
Mitsu Ruparel, Senior Conference Producer, Khaleej Times

    `,
  },
  {
    time: "13.25",
    title: "Speech - Fireside Chat IFRS 17 – The roadmap ahead",
    description: `
    IFRS17, the recently introduced accounting standard for the insurance industry, aims to bring in
    greater transparency and consistency. However, the industry has anticipated that its implementation could be a challenging, time-consuming, and costly process. With proper planning and execution, these challenges can be minimized. In this session, we will provide a blueprint for an end-to-end I FRS 17 program, thereby ensuring that the adoption process is streamlined and efficient.

<br/><br/>
<ul>
<li>Key challenges and solutions during the implementation of IFRS 17</li>
<li>Potential benefits- improved transparency and comparability of financial information</li>
<li>Impact on the business models and strategies of insurance companies in the UAE</li>
</ul><br/>

Speaker<br/>
Gaurav Baid, Senior Partner, AJMS Global
      `,
  },

  {
    time: "13.45",
    title: "Anchor Keynote - The Future of Reinsurance",
    description: `
    Speaker<br/>
Aftab Hassan, Chairman, Risk Exchange DIFC Limited
    `,
  },

  {
    time: "14.00",
    title: "Networking Lunch & Close of the Conference",
    description: ``,
  },

  //   {
  //     time: "14.10",
  //     title: "Fraud Detection and Management in Insurance: A Case Study",
  //     description: `Fraudulent claims in the insurance industry are a serious problem that can result in significant financial losses for insurance companies. Detecting and managing fraud effectively is critical to ensure the long-term sustainability of the industry.
  //     <br/>
  //     <br/>
  //     This case study will showcase a real-life example of fraud detection and management in action, highlighting best practices and strategies for preventing and managing fraud in the insurance industry.
  //     `,
  //   },
  //   {
  //     time: "14.30",
  //     title: "Anchor Keynote -  EI 2.0: The Next Frontier In Customer-centric Innovation",
  //     description: `Embedded Insurance 2.0 represents a significant opportunity for insurers to enhance the
  //     customer experience, diversify revenue streams, and improve risk management processes. The closing speech will delve into details on new opportunities for growth and innovation and potential impact on the region’s insurance industry.<br/>

  //     <ul>
  //     <li>How can embedded Insurance enhance value propositions and create new revenue streams? </li>
  //     <li>For society at large does it help close the insurance protection gap?</li>
  // <li>How can the insurance industry re-think its business model and deliver greater value to the market?</li>
  // </ul>
  // `,
  //   },

  //   {
  //     time: "15.00",
  //     title: "Networking Lunch & Close of the Conference",
  //     description: `

  //     `,
  //   },
];

export default Agenda;
