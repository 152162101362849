import React from "react";
import { Container, Row, Col } from "reactstrap";

function SpeakerModal({ speaker }) {
  if (speaker && speaker.image) {
    return (
      <>
        <Container>
          <div className="owner" style={{ paddingTop: "2rem" }}>
            <div className="name">
              <img
                src={speaker.image || require("assets/speakers/placeholder.png")}
                alt="speaker"
                width="100%"
                style={{ borderRadius: 0, maxWidth: "250px" }}
              />
              <h4 className="text-dark text-400 mt-3" style={{ marginBottom: "0" }}>
                {speaker.name}
              </h4>
              <p className="text-dark text-400" style={{ marginBottom: "0" }}>
                {speaker.title}
                <br />
                {speaker.company}
              </p>

              <br />
            </div>
          </div>
          <Row>
            <Col className="" md="12">
              <p className="text-dark text-400" dangerouslySetInnerHTML={{ __html: speaker.bio }} />
            </Col>
          </Row>
        </Container>
      </>
    );
  } else return "";
}

export default SpeakerModal;
