// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import Particles from "../Particles";
import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/images/background.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          overflow: "hidden",

          // backgroundColor: "rgb(224, 228, 229)",
        }}
        className='page-header'
        data-parallax={true}
      >
        {/* <Anime /> */}
        <Particles />
        <Container>
          <Row className='align-items-center'>
            <Col md={7} xs={12}>
              <img
                src={require(`assets/images/foilogo.png`)}
                // style={{ maxWidth: size ? "200px" : "150px" }}
                width='100%'
                alt='foi'
              />

              <hr />

              <h1
                className='text-700 text-danger m-0'
                style={{ fontSize: size ? "2.25rem" : "1rem" }}
              >
                30th May 2023
              </h1>
              <h2 className='text-700 text-dark mt-1'>
                Exploring the future of Insurance and Insurtech: Innovations &
                opportunities ahead
              </h2>
            </Col>

            <Col md={7} xs={12} className='mt-4' style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row>
                  <Col lg={6} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className='py-3 rounded-0'
                      color='primary'
                      style={{ width: "90%" }}
                      href='/register'
                      disabled
                    >
                      <p className='m-0 text-700' style={{ color: "#fff" }}>
                        REGISTRATION{size ? " " : <br />}CLOSED
                      </p>
                    </Button>
                  </Col>
                  <Col lg={6} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className='py-3 rounded-0'
                      color='primary'
                      style={{ width: "90%" }}
                      href='/sponsor/download-brochure'
                    >
                      <p className='m-0 text-700' style={{ color: "#fff" }}>
                        DOWNLOAD{size ? " " : <br />}BROCHURE
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
