import Particles from "react-particles-js";
import React from "react";

function ParticleBackground() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "2000px",
      }}
    >
      <Particles
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 7,
            },
            color: {
              value: "#15a2dd",
            },
            line_linked: {
              color: "#15a2dd",
            },
          },

          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "grab",
              },
            },
          },
        }}
      />
    </div>
  );
}

export default ParticleBackground;
