import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

// import ScrollAnimation from "react-animate-on-scroll";
// const size =
//   document.body.clientWidth >= 1024 ||
//   document.documentElement.clientWidth > 1024
//     ? true
//     : false;

function WhoAttend() {
  return (
    <>
      <div
        style={{
          
          overflow: "hidden",
        backgroundColor: "rgb(224, 228, 229)",
        }}
        className='section border-top border-bottom'
        data-parallax={true}
      >
        <Container className='py-4'>
          <Row>
            <Col lg={12}>
              <h1 className='section-title text-center p-2 text-dark'>
                High Profile And Influential Delegates
              </h1>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col lg={6} xs={12} className='py-2'>
              <Card className='p-5'>
                <ul>
                  {content.map((data, index) => (
                    <li>
                      <h3 className='text-700 text-dark'>{data}</h3>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
            <Col lg={6} xs={12} className='py-2'>
              <Card className='p-5'>
                <ul>
                  {content2.map((data, index) => (
                    <li>
                      <h3 className='text-700 text-dark'>{data}</h3>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  "Chief Executive Officer ",
  "SVP Insurance",
  "Chief Operating Officer",
  "Chief Innovation Officer",
  "Chief Digital Officer",
  "Explore business opportunities and potential tie-ups with global technology success partners.",
];
const content2 = [
  "Exclusive content & case-studies will make for the unique experience and leave you envisioning the future of your business",
  "Chief Marketing Officer",
  "Chief Information Officer",
  "Chief Data and Analytics Officer",
  "Chief Underwriting Officer",
  "Chief Risk Officer",
];

export default WhoAttend;
